import { cn } from '@/lib/utils';
import { fNumber } from 'src/utils/format';

// ----------------------------------------------------------------------
export type CarbonUnitVariantType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'overline';

export default function CarbonUnit({
  emissionValueInKg,
  variant = 'body1',
  omitEmissionValue = false,
  omitCo2eFromUnit = false,
  type = 'mass',
  className
}: {
  type?: 'density' | 'mass'; // density is kg/$
  variant?: CarbonUnitVariantType;
  emissionValueInKg?: number;
  omitEmissionValue?: boolean;
  omitCo2eFromUnit?: boolean;
  className?: string;
}) {
  let magnitude = 'kg';
  let emissionValue = Number(emissionValueInKg);

  if (emissionValue && emissionValue > 1000) {
    emissionValue /= 1000;
    magnitude = 't';
  }
  return (
    <div className={cn('flex max-w-full items-baseline', className)}>
      {!omitEmissionValue && (
        <span className={cn(variant)}>
          {fNumber(emissionValue.toFixed(2))}&nbsp;
        </span>
      )}
      <span className={cn(variant, 'text-nowrap')}>
        {magnitude}
        {!omitCo2eFromUnit && 'CO₂e'}
        {type === 'density' && '/$'}
      </span>
    </div>
  );
}
