import { ApolloProvider } from '@apollo/client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Router } from 'next/router';
import NProgress from 'nprogress';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';
import { IntercomProvider } from 'react-use-intercom';
import { PersistGate } from 'redux-persist/integration/react';
import { ENVIRONMENT, TOKENS } from 'src/config';
import { persistor } from '../archived/redux/store';
import LoadingScreen from '../src/components/core/atoms/LoadingScreen';
import { Toaster } from '../src/components/shad-base/toaster';
import useApollo from '../src/hooks/useApollo';
import { IntercomUtilsConfigurator } from '../src/utils/intercom';
import ReactGA from 'react-ga4';
import '../styles/customGraphiql.css';
import '../styles/globals.css';
import '../styles/nprogress.css';
import '../styles/output.css';
import '../styles/search.css';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// ----------------------------------------------------------------------

export const analytics = AnalyticsBrowser.load({
  writeKey:
    ENVIRONMENT == 'production'
      ? 'V1dqRfdNDYKKLE5H5s4sdKqf25ihtbcT'
      : ''
});

// ----------------------------------------------------------------------

const INTERCOM_APP_ID = 'uxf9i0l2';
const GTM_ID = ENVIRONMENT === 'production' ? 'G-WGG2LERSF3' : '';

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles?.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    // initialize Hotjar
    hotjar.initialize(4971284, 6);
  }, []);

  useEffect(() => {
    ReactGA.initialize(GTM_ID);

    // Send initial pageview
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname
    });

    // Track pathname changes
    const handleRouteChange = (url: string) => {
      ReactGA.send({
        hitType: 'pageview',
        page: url
      });
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  const apolloClient = useApollo();

  const app = (
    <HelmetProvider>
      <script
        async
        src={`https://maps.googleapis.com/maps/api/js?key=${TOKENS.GOOGLE_AUTOCOMPLETE_API_KEY}&loading=async&libraries=places&callback=Function.prototype`}
      ></script>
      <link
        href="https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css"
        rel="stylesheet"
      />
      <ApolloProvider client={apolloClient}>
        <PersistGate
          loading={<LoadingScreen />}
          persistor={persistor}
        >
          <>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
              />
            </Head>
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <IntercomUtilsConfigurator />
              <Component {...pageProps} />
              <Toaster />
            </IntercomProvider>
          </>
        </PersistGate>
      </ApolloProvider>
    </HelmetProvider>
  );

  return app;
}
