import { cn } from '@/lib/utils';
import { alpha } from 'src/theme/palette';
import { ChevronDown } from 'lucide-react';
import { CSSProperties } from 'react';
import { SCOPE_COLORS } from 'src/config';
import useSettingsStore, {
  SettingsStoreType
} from 'src/hooks/store/useSettingsStore';
import { getPalette } from 'src/theme/palette';
import typography from 'src/theme/typography';

// ----------------------------------------------------------------------

export type ChipColorType =
  | 'primary'
  | 'secondary'
  | 'warning'
  | 'amber'
  | 'error'
  | 'info'
  | 'white'
  | 'border'
  | '1' //scope 1
  | '2' //scope 2
  | '3'; //scope 3

export default function Chip({
  label,
  color: colorName = 'primary',
  size = 'small',
  onClick = null,
  ignoreTextTransform = false,
  isSelected = null,
  disabled = false,
  includeDropdown = false,
  className = '',
  style = null
}: {
  label: string | JSX.Element;
  color?: ChipColorType;
  disabled?: boolean;
  size?: 'mini' | 'small' | 'medium';
  type?: 'attribute' | 'scope' | 'unit';
  onClick?: (isSelected) => void;
  ignoreTextTransform?: boolean;
  isSelected?: boolean;
  includeDropdown?: boolean;
  className?: string;
  style?: CSSProperties;
}) {
  const { themeMode }: SettingsStoreType = useSettingsStore();
  const palette = getPalette({ themeMode });

  //default primary colors
  let backgroundColor = 'transparent';
  let borderColor = 'transparent';
  let colorValue = null;

  switch (colorName) {
    case 'primary':
      colorValue = palette.primary.main;
      break;
    case 'secondary':
      colorValue = palette.secondary.main;
      break;
    case 'white':
      colorValue = palette.common.white;
      break;
    case 'info':
      colorValue = palette.text.primary;
      break;
    case 'warning':
      colorValue = palette.warning.main;
      break;
    case 'amber':
      colorValue = palette.warning.dark;
      break;
    case 'error':
      colorValue = palette.error.main;
      break;
    case 'border':
      colorValue = palette.border.main;
      break;
    case '1':
      colorValue = SCOPE_COLORS['1'];
      break;
    case '2':
      colorValue = SCOPE_COLORS['2'];
      break;
    case '3':
      colorValue = SCOPE_COLORS['3'];
      break;
    default:
      colorValue = palette.text.primary;
      break;
  }

  // Set background color as alpha of color if no onClick
  backgroundColor = alpha(colorValue, 0.04);

  if ((onClick && isSelected === null) || (onClick && isSelected)) {
    borderColor = alpha(colorValue, 0.5);
  }

  return (
    <>
      <div
        className={cn(
          'inline-flex flex-nowrap items-center rounded-full ring-inset',
          size == 'mini' ? 'px-1 py-2 pb-3' : 'px-4 py-2',
          className,
          onClick && !disabled
            ? 'cursor-pointer hover:border-[var(--chip-border-color)] hover:ring-1'
            : '',
          disabled
            ? 'pointer-events-none cursor-default opacity-50'
            : ''
        )}
        style={{
          ...typography.body2,
          backgroundColor,
          borderColor,
          color: colorValue,
          ['--chip-border-color' as string]: alpha(colorValue, 0.5),
          // ['--test-color' as string]: 'red',; bg-[var(--test-color)]
          textTransform: ignoreTextTransform ? 'none' : 'lowercase',
          transition: 'all 0.2s ease-in-out',
          ...style
        }}
        onClick={
          onClick
            ? () => {
                onClick(!isSelected);
              }
            : null
        }
      >
        {label}
        {includeDropdown && (
          <ChevronDown
            color={colorValue}
            style={{ color: colorValue, transform: 'scale(0.65)' }}
          />
        )}
      </div>
    </>
  );
}

export function SpecialChip({
  label,
  type,
  size = 'small',
  onClick = null,
  selected = false,
  disabled = false,
  isLowerCase = false,
  className = '',
  style = null
}: {
  label: string;
  type: 'image' | 'b&w' | 'imports' | 'blog';
  size?: 'small' | 'medium';
  onClick?: () => void;
  selected?: boolean;
  color?: string;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  isLowerCase?: boolean;
}) {
  size;
  const { themeMode }: SettingsStoreType = useSettingsStore();
  const palette = getPalette({ themeMode });
  const lower = label?.toLowerCase();
  // let variant: 'outlined' | 'filled' = 'outlined';
  let labelProp = (
    <p
      style={{
        ...typography.overline,
        color: selected
          ? palette.background.default
          : palette.primary.main
      }}
    >
      {isLowerCase ? lower : label}
    </p>
  );
  let classNameValue = '';

  switch (type) {
    case 'image':
      labelProp = (
        <p
          className="text-white"
          style={
            isLowerCase ? typography.subtitle1 : typography.overline
          }
        >
          {label}
        </p>
      );
      style = {
        ...style,
        borderColor: palette.common.white,
        background: 'rgba(128,128,128,0.5)',
        backdropFilter: 'saturate(180%) blur(5px)'
      };
      classNameValue = 'border';
      break;

    case 'b&w':
      // variant = 'filled';
      labelProp = (
        <p className="text-black" style={typography.overline}>
          {label}
        </p>
      );
      style = {
        ...style,
        borderColor: palette.common.black,
        backgroundColor: 'white',
        color: palette.background.neutral
      };
      classNameValue = 'border-2';
      break;

    case 'blog':
      // variant = 'filled';
      labelProp = (
        <p
          style={{
            ...typography.overline,
            color: selected
              ? themeMode == 'light'
                ? 'white'
                : palette.common.black
              : palette.info.light
          }}
        >
          {label}
        </p>
      );
      style = {
        ...style,
        borderColor: selected
          ? palette.secondary.dark
          : palette.background.neutral,
        backgroundColor: selected
          ? palette.secondary.dark
          : palette.background.neutral,
        ['--special-chip-bgcolor' as string]: selected
          ? palette.secondary.dark
          : palette.common.white
        // ['--special-chip-bgcolor' as string]: 'red',; bg-[var(--test-color)]
      };
      classNameValue =
        'border-2 hover:bg-[var(--special-chip-bgcolor)]';
      break;
  }

  return (
    <div
      className={cn('py-4', className, classNameValue)}
      style={{ ...style }}
      onClick={!disabled && onClick}
    >
      {labelProp}
    </div>
  );
}
