import {
  BadgesEnum,
  DataCalculatorTypesEnum,
  GhgProtocolsEnum,
  GoalActionStatesEnum,
  MeasurementStatesEnum,
  SubscriptionFrequenciesEnum,
  SubscriptionPlanTiersEnum
} from 'src/__apolloGenerated__/graphql';
import { SubscriptionPlanType } from 'src/components/core/organisms/dialogs/SubscribeDialog';
import GeneralIcon, {
  GeneralIconType
} from './components/core/atoms/GeneralIcon';

import { Appearance } from '@stripe/stripe-js';
import {
  AlignJustify,
  BadgeCheck,
  Building,
  CalendarDays,
  Car,
  CloudDownload,
  CloudUpload,
  Fan,
  FilePlus,
  Lightbulb,
  Warehouse,
  Waves,
  Zap
} from 'lucide-react';
import { MutableRefObject } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig, {
  LEFT_SIDEBAR_WIDTH as BASE_SIDEBAR_WIDTH,
  TOPBAR_HEIGHT as BASE_TOPBAR_HEIGHT,
  EMISSION_CATEGORY_COLOURS
} from '../tailwind.config';
import { ScopeType } from './@types/emissions';

export const SCALING_RATIO = 0.72;
export const TOPBAR_HEIGHT = BASE_TOPBAR_HEIGHT;
export const TOPBAR_HEIGHT_PX = BASE_TOPBAR_HEIGHT + 'px';
export const SUBNAV_HEIGHT = BASE_TOPBAR_HEIGHT;
export const SUBNAV_HEIGHT_PX = BASE_TOPBAR_HEIGHT + 'px';
export const FILTERBAR_HEIGHT = 130 * SCALING_RATIO;
export const FILTERBAR_HEIGHT_PX = FILTERBAR_HEIGHT + 'px';
export const TOPBAR_ITEM_HEIGHT = 60 * SCALING_RATIO;
export const TOPBAR_ITEM_HEIGHT_PX = TOPBAR_ITEM_HEIGHT + 'px';
export const MARKETPLACE_TOPBAR_HEIGHT = TOPBAR_HEIGHT * 1.2;
export const MARKETPLACE_TOPBAR_HEIGHT_PX =
  MARKETPLACE_TOPBAR_HEIGHT + 'px';
export const FOOTER_HEIGHT = 700 * SCALING_RATIO;
export const FOOTER_HEIGHT_PX = FOOTER_HEIGHT + 'px';
export const SIDEBAR_WIDTH = BASE_SIDEBAR_WIDTH;
export const SIDEBAR_WIDTH_PX = BASE_SIDEBAR_WIDTH + 'px';
export const DOCS_DRAWER_WIDTH = 275 * SCALING_RATIO;
export const DOCS_DRAWER_WIDTH_PX = DOCS_DRAWER_WIDTH + 'px';
export const DOCS_MOBILE_TOPBAR_HEIGHT = TOPBAR_HEIGHT;
export const DOCS_MOBILE_TOPBAR_HEIGHT_PX =
  DOCS_MOBILE_TOPBAR_HEIGHT + 'px';
export const DRAWER_WIDTH = 500 * SCALING_RATIO;
export const DRAWER_WIDTH_PX = DRAWER_WIDTH + 'px';
export const BLOG_ITEM_WIDTH = 426 * SCALING_RATIO;
export const BLOG_ITEM_WIDTH_PX = BLOG_ITEM_WIDTH + 'px';
export const BLOG_ITEM_HEIGHT = 200 * SCALING_RATIO;
export const BLOG_ITEM_HEIGHT_PX = BLOG_ITEM_HEIGHT + 'px';
export const ENVIRONMENT =
  process.env.NEXT_PUBLIC_ENVIRONMENT || 'local';
export const IS_LOCAL_ENVIRONMENT = ENVIRONMENT == 'local';
export const IS_DEV_ENVIRONMENT = ENVIRONMENT == 'development';
export const IS_LOCAL_DEV_ENVIRONMENT =
  IS_LOCAL_ENVIRONMENT || IS_DEV_ENVIRONMENT;
export const ANIMATION_DURATION = 200;
export const CHEVRON_WIDTH = 45;
export const API_TIMEOUT = 7000;
export const FADEIN_TIMEOUT = 2000;
export const SCROLLBAR_WIDTH = 16;
export const SCROLLBAR_WIDTH_PX = SCROLLBAR_WIDTH + 'px';
export const BIOME_CARD_HEIGHT = 200 * SCALING_RATIO;
export const BIOME_CARD_HEIGHT_PX = BIOME_CARD_HEIGHT + 'px';
export const BIOME_CARD_WIDTH = 180 * SCALING_RATIO;
export const BIOME_CARD_WIDTH_PX = BIOME_CARD_WIDTH + 'px';
export const PROJECT_STAGE_CARD_HEIGHT = 45 * SCALING_RATIO;
export const PROJECT_STAGE_CARD_HEIGHT_PX =
  PROJECT_STAGE_CARD_HEIGHT + 'px';
export const PROJECT_STAGE_CARD_WIDTH = 180 * SCALING_RATIO;
export const PROJECT_STAGE_CARD_WIDTH_PX =
  PROJECT_STAGE_CARD_WIDTH + 'px';
export const MARKETPLACE_FILTER_BAR_WIDTH = 250 * SCALING_RATIO;
export const MARKETPLACE_FILTER_BAR_WIDTH_PX =
  MARKETPLACE_FILTER_BAR_WIDTH + 'px';
export const MARKETPLACE_FILTER_BAR_HEIGHT = 75 * SCALING_RATIO;
export const MARKETPLACE_FILTER_BAR_HEIGHT_PX =
  MARKETPLACE_FILTER_BAR_HEIGHT + 'px';
export const ICON_FILTER_WIDTH = 80 * SCALING_RATIO;
export const ICON_FILTER_WIDTH_PX = ICON_FILTER_WIDTH + 'px';
export const MOBILE_ICON_FILTER_WIDTH = 70 * SCALING_RATIO;
export const MOBILE_ICON_FILTER_WIDTH_PX =
  MOBILE_ICON_FILTER_WIDTH + 'px';
export const SEARCHBAR_BOX_WIDTH = 300 * SCALING_RATIO;
export const IFRAME_WIDTH = 430;
export const IFRAME_HEIGHT = 740;
export const IFRAME_SCALING_RATIO = 0.8;
export const DEFAULT_PADDING_X = 4;
export const DEFAULT_PADDING_Y = 4;
export const FOOTPRINT_TREE_ROW_HEIGHT = 40;
export const FOOTPRINT_TREE_ROW_HEIGHT_PX =
  FOOTPRINT_TREE_ROW_HEIGHT + 'px';
export const DEFAULT_COLUMN_WIDTH = 150 * SCALING_RATIO;
export const DEFAULT_COLUMN_WIDTH_PX = DEFAULT_COLUMN_WIDTH + 'px';

const DEFAULT_CARD_WIDTH = 275 * SCALING_RATIO;
const DEFAULT_CARD_HEIGHT = 350 * SCALING_RATIO;
export const SIMPLE_CARD_WIDTH = {
  XS: DEFAULT_CARD_WIDTH * 0.56,
  SM: DEFAULT_CARD_WIDTH * 0.8,
  MD: DEFAULT_CARD_WIDTH,
  LG: DEFAULT_CARD_WIDTH * 1.65,
  XL: DEFAULT_CARD_WIDTH * 2
};

export const SIMPLE_CARD_WIDTH_PX = {
  XS: SIMPLE_CARD_WIDTH.XS + 'px',
  SM: SIMPLE_CARD_WIDTH.SM + 'px',
  MD: SIMPLE_CARD_WIDTH.MD + 'px',
  LG: SIMPLE_CARD_WIDTH.LG + 'px',
  XL: SIMPLE_CARD_WIDTH.XL + 'px'
};

export const SIMPLE_CARD_HEIGHT = {
  XS: DEFAULT_CARD_HEIGHT * 0.56,
  SM: DEFAULT_CARD_HEIGHT * 0.8,
  MD: DEFAULT_CARD_HEIGHT,
  LG: DEFAULT_CARD_HEIGHT * 1.65,
  XL: DEFAULT_CARD_HEIGHT * 2
};

export const SIMPLE_CARD_HEIGHT_PX = {
  XS: SIMPLE_CARD_HEIGHT.XS + 'px',
  SM: SIMPLE_CARD_HEIGHT.SM + 'px',
  MD: SIMPLE_CARD_HEIGHT.MD + 'px',
  LG: SIMPLE_CARD_HEIGHT.LG + 'px',
  XL: SIMPLE_CARD_HEIGHT.XL + 'px'
};

export const PROJECT_CARD_HEIGHT = {
  MARKETPLACE: 250 * SCALING_RATIO,
  MARKETPLACE_PX: 250 * SCALING_RATIO + 'px',
  MY_OFFSETS: 300 * SCALING_RATIO,
  MY_OFFSETS_PX: 300 * SCALING_RATIO + 'px',
  DASHBOARD: 280 * SCALING_RATIO,
  DASHBOARD_PX: 280 * SCALING_RATIO + 'px',
  CART: 150 * SCALING_RATIO,
  CART_PX: 150 * SCALING_RATIO + 'px'
};

export const PROJECT_CARD_WIDTH = {
  MARKETPLACE: 360 * SCALING_RATIO,
  MARKETPLACE_PX: 360 * SCALING_RATIO + 'px',
  MY_OFFSETS: 500 * SCALING_RATIO,
  MY_OFFSETS_PX: 500 * SCALING_RATIO + 'px',
  DASHBOARD: 180 * SCALING_RATIO,
  DASHBOARD_PX: 180 * SCALING_RATIO + 'px',
  CART: 150 * SCALING_RATIO,
  CART_PX: 150 * SCALING_RATIO + 'px'
};

export const STATE_COLORS = {
  COMPLETED: 'primary.main',
  PENDING: 'info.light',
  FAILED: 'error.main',
  SENT: 'secondary.main'
};

export const MAX_PRICE_SLIDER_VALUE = 100;
export const MAX_VOLUME_SLIDER_VALUE = 1000000000;
export const MAX_SIZE_SLIDER_VALUE = 100000;

export const COMPANY_NAME = 'EmitIQ';
export const COMPANY_URL = IS_LOCAL_ENVIRONMENT
  ? 'localhost:3111'
  : 'emitiq.com';
export const FULL_COMPANY_URL =
  (IS_LOCAL_ENVIRONMENT ? 'http://' : 'https://') + COMPANY_URL;
export const COMPANY_TAGLINE = 'Making sustainability profitable';

export const GOOGLE_AUTOCOMPLETE_API_KEY = '';

const urls = {
  local: {
    WEBAPP: 'http://127.0.0.1:3111',

    // Local
    REST: 'http://127.0.0.1:8000',
    // GRAPHQL: 'http://127.0.0.1:8000/graphql/',
    GRAPHQL: 'https://api.dev.emitiq.com/graphql/',
    GRAPHQL_QS: 'ws://api.dev.emitiq.com/graphql/'

    // Prod
    // REST: 'https://api.emitiq.com/',
    // GRAPHQL: 'https://api.emitiq.com/graphql/',
    // GRAPHQL_QS: 'ws://api.emitiq.com/graphql/'
  },
  development: {
    WEBAPP: 'https://dev.emitiq.com/',
    REST: 'https://api.dev.emitiq.com/',
    GRAPHQL: 'https://api.dev.emitiq.com/graphql/',
    GRAPHQL_QS: 'ws://api.dev.emitiq.com/graphql/'
  },
  production: {
    WEBAPP: 'https://www.emitiq.com/',
    REST: 'https://api.emitiq.com/',
    GRAPHQL: 'https://api.emitiq.com/graphql/',
    GRAPHQL_QS: 'ws://api.emitiq.com/graphql/'
  }
};
export const tokens = {
  local: {
    STRIPE: {
      PUBLIC_KEY:
        'pk_test_51OworMP4WAi6CgqsQP2ul1efTIsJdqiYmjK6XGlGAuyBFSJtexKjAMAwYQn32Dw1YQ75qa9BG3pPDQZ8At2KpLXR00Qf8Vwwl8'
    },
    GOOGLE_AUTOCOMPLETE_API_KEY:
      'AIzaSyB9mpxu99NCJyZ7TH5hjM8ePvx2mI8BvhA',
    MAPBOX:
      'pk.eyJ1IjoiZW1pdGlxIiwiYSI6ImNseXJseDV5aTA2ODYycW9wcWNjNHlzajUifQ._vz83blCdg1VNX2ic2D3fg'
  },
  development: {
    STRIPE: {
      PUBLIC_KEY:
        'pk_test_51OworMP4WAi6CgqsQP2ul1efTIsJdqiYmjK6XGlGAuyBFSJtexKjAMAwYQn32Dw1YQ75qa9BG3pPDQZ8At2KpLXR00Qf8Vwwl8'
    },
    GOOGLE_AUTOCOMPLETE_API_KEY:
      'AIzaSyB9mpxu99NCJyZ7TH5hjM8ePvx2mI8BvhA',
    MAPBOX:
      'pk.eyJ1IjoiZW1pdGlxIiwiYSI6ImNseXJseDV5aTA2ODYycW9wcWNjNHlzajUifQ._vz83blCdg1VNX2ic2D3fg'
  },
  production: {
    STRIPE: {
      PUBLIC_KEY:
        'pk_live_51OworMP4WAi6CgqsHDxJwsT0BPQGkZshXNSwmz4y0xuL71dHwez4gep3LGRPOVkhqoZA0GXPB1bjDgT1TW1cioNB0085rCADJ2'
    },
    GOOGLE_AUTOCOMPLETE_API_KEY:
      'AIzaSyBxQTtNahkpTXgiOourOZGlxCvvbUHD_hQ',
    MAPBOX:
      'pk.eyJ1IjoiZW1pdGlxIiwiYSI6ImNseXJseDV5aTA2ODYycW9wcWNjNHlzajUifQ._vz83blCdg1VNX2ic2D3fg'
  }
};

export const URLS = urls[ENVIRONMENT];
export const TOKENS = tokens[ENVIRONMENT];

export const BANK_OBJECTS = [
  {
    name: 'RBC',
    logo: '/static/bank/rbc.svg',
    plaidId: 'ins_39'
  },
  {
    name: 'TD',
    logo: '/static/bank/td.svg',
    plaidId: 'ins_42'
  },
  {
    name: 'CIBC',
    logo: '/static/bank/cibc.svg',
    plaidId: 'ins_37'
  },
  {
    name: 'BMO',
    logo: '/static/bank/bmo.svg',
    plaidId: 'ins_41'
  },
  {
    name: 'Scotiabank',
    logo: '/static/bank/scotiabank.svg',
    plaidId: 'ins_38'
  },
  {
    name: 'Coast Capital',
    logo: '/static/bank/coast_capital.svg',
    plaidId: 'ins_119424'
  },
  {
    name: 'HSBC',
    logo: '/static/bank/hsbc.svg',
    plaidId: ''
  },
  {
    name: 'National Bank',
    logo: '/static/bank/nbc.svg',
    plaidId: 'ins_48'
  },
  {
    name: 'Desjardins',
    logo: '/static/bank/desjardins.svg',
    plaidId: 'ins_46'
  },
  {
    name: 'Tangerine',
    logo: '/static/bank/tangerine.svg',
    plaidId: 'ins_40'
  },
  {
    name: 'Simplii',
    logo: '/static/bank/simplii.svg',
    plaidId: 'ins_115631'
  },
  {
    name: 'vancity',
    logo: '/static/bank/vancity.svg',
    plaidId: 'ins_115575'
  },
  {
    name: 'meridian',
    logo: '/static/bank/meridian.svg',
    plaidId: 'ins_118297'
  }
];

export const CREDIT_CARDS = [
  {
    name: 'American Express',
    logo: '/static/creditCards/amex.svg'
  },
  {
    name: 'Mastercard',
    logo: '/static/creditCards/mastercard.svg'
  },
  {
    name: 'Visa',
    logo: '/static/creditCards/visa.svg'
  },
  {
    name: 'Discover',
    logo: '/static/creditCards/discover.svg'
  },
  {
    name: 'Diners Club',
    logo: '/static/creditCards/diners_club.svg'
  },
  {
    name: 'JCB',
    logo: '/static/creditCards/jcb.svg'
  },
  {
    name: 'UnionPay',
    logo: '/static/creditCards/unionpay.svg'
  }
];

export const TICKER_INFO = {
  CAD: {
    image: '/static/currency/cad.webp',
    name: 'Canadian Dollar',
    alpha_code: 'CAD'
  },
  USD: {
    image: '/static/currency/usd.webp',
    name: 'US Dollar',
    alpha_code: 'USD'
  },

  BTC: {
    image: '/static/currency/btc.png',
    name: 'Bitcoin',
    alpha_code: 'BTC'
  },
  BAT: {
    image: '/static/currency/bat.webp',
    name: 'Basic Attention Token',
    alpha_code: 'BAT'
  },
  ETH: {
    image: '/static/currency/eth.svg',
    name: 'Ethereum',
    alpha_code: 'ETH'
  },
  LTC: {
    image: '/static/currency/ltc.webp',
    name: 'Litecoin',
    alpha_code: 'LTC'
  },
  GRT: {
    image: '/static/currency/grt.svg',
    name: 'The Graph Token',
    alpha_code: 'GRT'
  },
  CTSI: {
    image: '/static/currency/ctsi.png',
    name: 'Cartesi Token',
    alpha_code: 'CTSI'
  },
  KNC: {
    image: '/static/currency/knc.svg',
    name: 'Kyber Network Crystal Token',
    alpha_code: 'KNC'
  },
  ADA: {
    image: '/static/currency/ada.webp',
    name: 'Cardano',
    alpha_code: 'ADA'
  },
  CHR: {
    image: '/static/currency/chr.png',
    name: 'Chromia Token',
    alpha_code: 'CHR'
  },
  YFI: {
    image: '/static/currency/yfi.png',
    name: 'yearn.finance Token',
    alpha_code: 'YFI'
  },
  AMP: {
    image: '/static/currency/amp.png',
    name: 'AMP Token',
    alpha_code: 'AMP'
  },
  LRC: {
    image: '/static/currency/lrc.svg',
    name: 'Loopring Token',
    alpha_code: 'LRC'
  },
  STORJ: {
    image: '/static/currency/storj.svg',
    name: 'Storj Token',
    alpha_code: 'STORJ'
  },
  RNDR: {
    image: '/static/currency/rndr.png',
    name: 'Render Token',
    alpha_code: 'RNDR'
  },
  QNT: {
    image: '/static/currency/qnt.svg',
    name: 'Quant Token',
    alpha_code: 'QNT'
  },
  DYDX: {
    image: '/static/currency/dydx.svg',
    name: 'dYdX Token',
    alpha_code: 'DYDX'
  },
  OMG: {
    image: '/static/currency/omg.webp',
    name: 'OMG Network Token',
    alpha_code: 'OMG'
  },
  CRV: {
    image: '/static/currency/crv.svg',
    name: 'Curve DAO Token',
    alpha_code: 'CRV'
  },
  QCAD: {
    image: '/static/currency/qcad.svg',
    name: 'CAD stablecoin',
    alpha_code: 'QCAD'
  },
  LINK: {
    image: '/static/currency/link.svg',
    name: 'Chainlink Token',
    alpha_code: 'LINK'
  },
  APE: {
    image: '/static/currency/ape.svg',
    name: 'ApeCoin Token',
    alpha_code: 'APE'
  },
  KLAY: {
    image: '/static/currency/klay.png',
    name: 'Klaytn',
    alpha_code: 'KLAY'
  },
  MANA: {
    image: '/static/currency/mana.svg',
    name: 'Decentraland Token',
    alpha_code: 'MANA'
  },
  CHZ: {
    image: '/static/currency/chz.png',
    name: 'Chiliz Token',
    alpha_code: 'CHZ'
  },
  AVAX: {
    image: '/static/currency/avax.svg',
    name: 'Avalanche',
    alpha_code: 'AVAX'
  },
  GALA: {
    image: '/static/currency/gala.svg',
    name: 'Gala Token',
    alpha_code: 'GALA'
  },
  SAND: {
    image: '/static/currency/sand.svg',
    name: 'The Sandbox Token',
    alpha_code: 'SAND'
  },
  CELO: {
    image: '/static/currency/celo.svg',
    name: 'Celo',
    alpha_code: 'CELO'
  },
  AAVE: {
    image: '/static/currency/aave.svg',
    name: 'Aave Token',
    alpha_code: 'AAVE'
  },
  CRO: {
    image: '/static/currency/cro.svg',
    name: 'Cronos Token',
    alpha_code: 'CRO'
  },
  FTM: {
    image: '/static/currency/ftm.svg',
    name: 'Fantom',
    alpha_code: 'FTM'
  },
  DAI: {
    image: '/static/currency/dai.png',
    name: 'Dai Token',
    alpha_code: 'DAI'
  },
  MKR: {
    image: '/static/currency/mkr.svg',
    name: 'Maker Token',
    alpha_code: 'MKR'
  },
  USDT: {
    image: '/static/currency/usdt.svg',
    name: 'Tether Token',
    alpha_code: 'USDT'
  },
  REN: {
    image: '/static/currency/ren.webp',
    name: 'Ren Token',
    alpha_code: 'REN'
  },
  ENJ: {
    image: '/static/currency/enj.svg',
    name: 'Enjin Coin Token',
    alpha_code: 'ENJ'
  },
  PAXG: {
    image: '/static/currency/paxg.svg',
    name: 'PAX Gold Token',
    alpha_code: 'PAXG'
  },
  SNX: {
    image: '/static/currency/snx.svg',
    name: 'Synthetix Token',
    alpha_code: 'SNX'
  },
  UMA: {
    image: '/static/currency/uma.png',
    name: 'UMA Token',
    alpha_code: 'UMA'
  },
  MATIC: {
    image: '/static/currency/matic.svg',
    name: 'Polygon',
    alpha_code: 'MATIC'
  },
  SUSHI: {
    image: '/static/currency/sushi.png',
    name: 'SushiSwap Token',
    alpha_code: 'SUSHI'
  },
  ZRX: {
    image: '/static/currency/zrx.svg',
    name: '0x Token',
    alpha_code: 'ZRX'
  },
  BAL: {
    image: '/static/currency/bal.svg',
    name: 'Balancer Token',
    alpha_code: 'BAL'
  },
  SHIB: {
    image: '/static/currency/shib.svg',
    name: 'Shiba Inu Token',
    alpha_code: 'SHIB'
  },
  ANKR: {
    image: '/static/currency/ankr.svg',
    name: 'Ankr Token',
    alpha_code: 'ANKR'
  },
  MASK: {
    image: '/static/currency/mask.svg',
    name: 'Mask Network Token',
    alpha_code: 'MASK'
  },
  UNI: {
    image: '/static/currency/uni.svg',
    name: 'Uniswap Token',
    alpha_code: 'UNI'
  },
  COMP: {
    image: '/static/currency/comp.svg',
    name: 'Compound Token',
    alpha_code: 'COMP'
  },
  INCH: {
    image: '/static/currency/inch.svg',
    name: '1inch Network Token',
    alpha_code: 'INCH'
  },
  CAT: {
    image: '/static/emitiq/cat.webp',
    name: '',
    alpha_code: 'CAT'
  },
  PLACEHOLDER: {
    image: '/static/placeholder.svg',
    name: '',
    alpha_code: ''
  }
};

export const FIAT_CURRENCIES = ['CAD', 'USD'];

export const INSTITUTION_INFO = {
  '001': { image: '/static/bank/bmo.svg', name: 'Bank of Montreal' },
  '002': { image: '/static/bank/scotiabank.svg', name: 'Scotiabank' },
  '003': {
    image: '/static/bank/rbc.svg',
    name: 'Royal Bank of Canada'
  },
  // '000': { image: '/static/bank/atb_finacial.svg', name: 'ATB Financial' },
  // '000': { image: '/static/bank/cibc.svg', name: 'Canadian Imperial Bank of Commerce' },
  // '000': { image: '/static/bank/coast_capital.svg', name: 'Coast Capital' },
  // '000': { image: '/static/bank/cwb.svg', name: 'Canadian Western Bank' },
  // '000': { image: '/static/bank/desjardins.svg', name: 'Desjardins' },
  // '000': { image: '/static/bank/eq_bank.svg', name: 'Equitable Bank' },
  // '000': { image: '/static/bank/flinks_capital.svg', name: 'Flinks Capital' },
  // '000': { image: '/static/bank/hsbc.svg', name: 'HSBC' },
  // '000': { image: '/static/bank/laurentian.svg', name: 'Laurentian Bank' },
  // '000': { image: '/static/bank/meridian.svg', name: 'Meridian Credit Union' },
  // '000': { image: '/static/bank/nbc.svg', name: 'National Bank of Canada' },
  // '000': { image: '/static/bank/simplii.svg', name: 'Simplii Financial' },
  // '000': { image: '/static/bank/tangerine.svg', name: 'Tangerine Bank' },
  // '000': { image: '/static/bank/td.svg', name: 'TD Canada Trust' },
  // '000': { image: '/static/bank/vancity.svg', name: 'Vancouver City Savings Credit Union' },

  // '001': { image: '/static/emitiq/bmo_sign.webp', name: 'Bank of Montreal' },
  // '002': { image: '/static/emitiq/scotia_sign.webp', name: 'Scotiabank' },
  // '003': { image: '/static/emitiq/rbc_sign.webp', name: 'Royal Bank of Canada' },

  // For testing
  '123': {
    image: '/static/bank/rbc.svg',
    name: 'Royal Bank of Canada'
  }
};

export const STATE = {
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  BLOCKED: 'BLOCKED',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  DEFERRED: 'DEFERRED',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
  REQUESTED: 'REQUESTED',
  SENT: 'SENT',
  UNAPPROVED: 'UNAPPROVED',
  PENDING: 'PENDING'
};

export const STATE_DESCRIPTIONS = {
  ACCEPTED: '',
  BLOCKED: '',
  CANCELLED: '',
  COMPLETED: '',
  DEFERRED: '',
  ERROR: '',
  FAILED: '',
  INSUFFICIENT_FUNDS: '',
  REQUESTED: '',
  SENT: 'Funds have been sent to recipient. Waiting for approval.',
  UNAPPROVED: ''
};

export const ROUTES = {
  ROOT: '/',
  ESTIMATE: '/estimate', // NOT USED
  COMING_SOON: '/soon', // NOT USED
  CARBON: '/carbon', // NOT USED
  WIP: '/work-in-progress', // NOT USED
  ENTERPRISE: '/enterprise', // NOT USED
  INVESTORS: '/investors', // NOT USED
  OPEN_IMPACT_STANDARD: {
    LIST: '/open-impact-standard',
    DETAIL: (namespace: string) => {
      return `/open-impact-standard/${namespace}`;
    },
    NEW_PROJECT: '/open-impact-standard/new-project'
  },
  MITIGATE: {
    TARGET: '/mitigate/target',
    STRATEGIES: '/mitigate/strategies',
    ACTIONS: '/mitigate/actions',
    INCENTIVES: '/mitigate/incentives'
  },
  REPORTS: {
    ESR: '/reports/ESR',
    TCFD: '/reports/TCFD',
    CDP: '/reports/CDP'
  },
  DIRECTORY: '/directory',
  TOS: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  ADVANCED_SETTINGS: '/advanced-settings',
  MARKETING: '/marketing',
  CHECKOUT: '/checkout',
  COMPLIANCE: '/compliance',
  DYP: '/doing-your-part',
  PLAYGROUND: '/playground',
  DOCS: {
    LIST: '/docs/',
    DETAIL: (page: string) => {
      return `/docs/${page.toLowerCase()}`;
    }
  },
  PRODUCTS: {
    // EMIT_INNOVATE
    REMOVAL: '/products/removal',
    REPORTING: '/products/reporting',
    OIS: '/products/ois',
    COLLABORATION: '/products/collaboration',
    BLOCKCHAIN: '/products/blockchain',
    // EMIT_OPS
    MEASUREMENT: '/products/measurement',
    // EMIT_EXCHANGE
    MARKETPLACE: '/products/marketplace',
    AI: '/products/ai',
    DIRECTORY: '/products/directory'
    // PROJECT_FINANCING: '/products/project-financing',
    // SUSTAINABILITY_REPORTING: '/products/sustainability-reporting',
  },
  AUTH: {
    SIGNIN: '/auth/signin',
    SIGNUP: '/auth/signup',
    VERIFY: '/auth/verify',
    RESET: '/auth/reset-request',
    COMPANY_INFO: '/auth/company-info'
  },
  COMPANY: {
    CAREERS: '/company/careers',
    CONTACT: '/company/contact',
    SECURITY: '/company/security',
    INVESTMENT: '/company/investment',
    CUSTOMERS: '/company/customers'
  },
  RESOURCES: {
    HELP_CENTER: '/resources/help-center',
    ABOUT: '/resources/about',
    FAQ: '/resources/faq',
    PRICING: '/resources/pricing',
    SUPPORT: '/resources/support',
    BLOG: {
      LIST: '/resources/blog',
      EDIT: '/resources/blog/edit',
      CREATE: 'resources/blog/create',
      EDIT_DETAIL: (postTitle: string) => {
        return `/resources/blog/${postTitle}`;
      },
      DETAIL: (postTitle: string) => {
        return `/resources/blog/${postTitle}`;
      }
    }
  },
  STANDARDS: {
    SCIENCE_BASED_TARGETS: '/',
    GHG_PROTOCOL: '/',
    TCFD_DISCLOSURE: '/',
    CDP_DISCLOSURE: '/'
  },
  SOLUTIONS: {
    FOR_AUDITORS: '/solutions/for-auditors',
    FOR_PROJECT_DEVELOPERS: '/solutions/for-project-developers',
    FOR_ORGANIZATIONS: '/solutions/for-organizations',
    FOR_INVESTORS: '/solutions/for-investors'
  },
  SOCIAL: {
    TWITTER: 'https://twitter.com/emitiq',
    LINKEDIN: 'https://linkedin.com/company/emitiq',
    INSTAGRAM: 'https://instagram.com/emitiq',
    FACEBOOK: 'https://facebook.com/emitiq'
  },
  DASHBOARD: '/dashboard',
  INVENTORY: '/inventory',
  USER: {
    ACCOUNT: '/user/account'
  },
  SETTINGS: {
    BILLING: '/settings/billing',
    INTEGRATIONS: '/settings/integrations',
    TEAM: '/settings/team',
    OVERVIEW: '/settings/overview',
    ROLES: '/settings/roles'
  },
  MARKETPLACE: {
    LIST: '/marketplace',
    DETAIL: (projectIdentifier: string) => {
      return `/marketplace/${projectIdentifier}`;
    }
  },
  SHARED: {
    LIST: '/shared',
    DETAIL: (branchIdentifier: string) => {
      return `/shared/${branchIdentifier}`;
    }
  },
  TOOLS: {
    SCOPE1: '/tools/scope1',
    SCOPE2: '/tools/scope2',
    SCOPE3: '/tools/scope3'
  },
  ORGANIZATION: {
    LOCATIONS: '/organization/locations',
    VEHICLES: '/organization/vehicles',
    EQUIPMENT: '/organization/equipment'
  },
  COLLISION_SURVEY: {
    WELCOME: '/public/collision-survey-welcome',
    QR_CODE: '/public/collision-conference-qr',
    COMPLETE: '/public/collision-survey-complete',
    SURVEY: (inviteIdentifier: string) => {
      return `/collision-survey/${inviteIdentifier}`;
    }
  },
  PUBLIC: {
    SURVEYS: {
      COMPLETED: (surveyIdentifier: string) => {
        return `/public/carbon-footprint-survey/completed/${surveyIdentifier}`;
      }
    },
    BRANCH: (branchIdentifier: string) =>
      `/public/shared/${branchIdentifier}`,
    REPORTING: {
      LIST: '/public/reporting',
      DETAIL: (reportingIdentifier: string) => {
        return `/public/reporting/${reportingIdentifier}`;
      }
    }
  }
};

export const BLOG_ITEMS: {
  title: string;
  description: string;
  link: string;
  github_link: string;
}[] = [
  {
    title: 'What is Cryptography?',
    description:
      'What are some examples of cryptography? What are the main principles of cryptography? How is cryptography used today? How is cryptography used in computer science?',
    link: '/blog/what-is-cryptography',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-is-cryptography.md'
  },
  {
    title: 'What is Blockchain?',
    description:
      'Why is it called blockchain? What does it mean to be decentralized? What are nodes, and how do they provide decentralization? What does it mean for data to be immutable? What are smart contracts?',
    link: '/blog/what-is-blockchain',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-is-blockchain.md'
  },
  {
    title: 'What is an NFT?',
    description:
      'What is minting? What is tokenization? What are some use cases for NFTs?',
    link: '/blog/what-is-an-nft',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-is-an-NFT.md'
  },
  {
    title: 'What are Carbon Credits?',
    description:
      'How are carbon credits awarded? How are carbon credits valued and transacted? Is it safe to invest in carbon credits? Is it safe to invest in projects producing carbon credits?',
    link: '/blog/what-are-carbon-credits',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-are-carbon-credits.md'
  },
  {
    title: 'What are Carbon Markets?',
    description:
      'What are compliance markets? What are voluntary markets? How can one invest in carbon credits, and what are the risks? Who participates in carbon markets?',
    link: '/blog/what-are-carbon-markets',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-are-carbon-markets.md'
  },
  {
    title:
      'What is the difference between Symmetric and Asymmetric-Key Encryption?',
    description:
      'What is a cryptographic key? What is symmetric-key encryption? Where is symmetric-key encryption used? What are the downfalls of symmetric-key encryption? hat is asymmetric-key encryption? Where is asymmetric-key encryption used?',
    link: '/blog/symmetric-vs-asymmetric-key-encryption',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/symmetric-vs-asymmetric-key-encryption.md'
  },
  {
    title: 'How do Voluntary Projects report Carbon Offsets?',
    description:
      'What documents are required? How is the activity of projects made transparent? What kind of calculations are required? What are the maintenance requirements and risks of a carbon project?',
    link: '/blog/how-do-voluntary-projects-report-carbon-offsets',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/how-do-voluntary-projects-report-carbon-offsets.md'
  },
  {
    title: 'How can Businesses benefit from Carbon Accounting?',
    description:
      'What are the benefits? Learn how you can get a leg up on competitors by being carbon neutral - and how carbon accounting can help you get there.',
    link: '/blog/how-can-businesses-benefit-from-carbon-accounting',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/how-can-businesses-benefit-from-carbon-accounting.md'
  },
  {
    title: 'What is Basic Attention Token (BAT)?',
    description:
      'What is the Brave browser? How does Basic Attention Token work with Brave? Why is the use case for Basic Attention Token (BAT) so special? What are tokenomics? Who created Basic Attention Token (BAT)?',
    link: '/blog/what-is-basic-attention-token-(BAT)',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-is-basic-attention-token-(BAT).md'
  },
  {
    title: 'What are Financial Risks?',
    description:
      'What is Operational Risk? What is Compliance Risk? What is Market Risk? What is Liquidity Risk? What is Credit Risk? What is Systemic Risk?',
    link: '/blog/what-are-financial-risks',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/what-are-financial-risks.md'
  },
  {
    title: 'What is the Carbon Footprint of Cryptocurrency?',
    description:
      'What is the difference between digital currency and cryptocurrency? What is the most popular system that these cryptocurrencies use? What is mining? How harmful is the pollution from proof-of-work? Is there an alternative to mining? What is staking? What cryptocurrencies use staking?',
    link: '/blog/carbon-footprint-of-digital-currency-and-cryptocurrency',
    github_link:
      'https://raw.githubusercontent.com/emitiq/core-website/main/blog/carbon-footprint-of-digital-currency-and-cryptocurrency.md'
  }
];

export const Z_INDEX = {
  SIDEBAR_DRAWER: 200,
  FILTERBAR_DRAWER: 350,
  PAGE: 400,
  SEARCHBAR: 500,
  TOPBAR: 600,
  SIDEBAR_ICONBAR: 650,
  SIDEBAR_ICONS: 700,
  MENU_POPPER: 800,
  SEARCH: 900,
  DIALOG: 950,
  CURSOR: 1000
};

//All pages that show up in SearchDialog
export type PageContextEnum =
  | 'home'
  | 'dashboard'
  | 'marketplace'
  | 'organization'
  | 'locations'
  | 'vehicles'
  | 'equipment'
  | 'inventory'
  | 'measure'
  | 'reduce'
  | 'share'
  | 'pricing'
  | 'playground'
  | 'signin'
  | 'signup'
  | 'settings';

export const PAGE_LABELS: { [key in PageContextEnum]: string } = {
  home: 'Home',
  dashboard: 'Dashboard',
  marketplace: 'Marketplace',
  // 'my-offsets': 'My Offsets',
  organization: 'Organization',
  locations: 'Locations',
  vehicles: 'Vehicles',
  equipment: 'Equipment',
  inventory: 'Carbon Inventory',
  measure: 'Measure',
  reduce: 'Reduce',
  share: 'Share',
  pricing: 'Pricing',
  playground: 'GraphQL Playground',
  // docs: 'Docs',
  signin: 'Sign In',
  signup: 'Sign Up',
  settings: 'Settings'
};

//key: page that was searched on
//value: all the pages that pertain to that search result
//(navigating to a page not on that list will reset the search value)
export const RELEVANT_SEARCH_PAGES = {
  marketplace: ['/marketplace/[identifier]', '/marketplace']
};

export const FOUNDERS = [
  {
    img: '/static/people/founder/brent_blank.jpg',
    name: 'Brent Story',
    shortTitle: 'CEO & Co-Founder',
    education: ' ',
    title: 'Chief Executive Officer',
    description:
      'Brent has experience in a wide array of industries, and has built a diverse portfolio of successes. He  brings a plethora of business and interpersonal skills to the table—exuding transparency, motivation, and accountability from the top down.'
  },
  {
    img: '/static/people/founder/michael_blank.jpg',
    name: 'Michael Davidoff',
    shortTitle: 'Co-Founder, COO',
    education: 'BSc. Engineering',
    title: 'Chief Operating Officer',
    description:
      'Michael is a scientist and software developer with a blend of experience in tech startups and pharmaceutical R&D. His passion lies in crafting solutions for our planet`s greatest challenges, particularly in health and climate change technologies.'
  },
  {
    img: '/static/people/founder/ryan_blank.jpg',
    name: 'Ryan Lider',
    shortTitle: 'Co-Founder, CTO',
    education: 'BSc. Engineering',
    title: 'Chief Technology Officer',
    description:
      'Ryan is a cybersecurity expert and software developer with 19 years of development experience. Previously serving as the CIO and co-founder of Origen Air, Ryan is passionate about generating an empathetic understanding of user problems, and solving them by developing great products.'
  }
];

export const PARTNERS = [
  {
    name: 'Kelly Sherman',
    credentials: 'MBA (Finance), B.Sc., RPF',
    title: 'President and CEO, Ecora',
    description:
      'Kelly has been a registered professional forester in BC (#3811) since 2000. In addition to his role as  president and CEO of Ecora, Kelly is a forest carbon specialist with over 22 years of diverse experience, during which he built many partnerships with various First Nations groups.'
  }
];

export const CAREER_LISTINGS = [
  {
    position: 'Senior Software Developer',
    chips: ['Software', 'Stock Options'],
    description: [
      'Strong familiarity with developing and refactoring codebases to create Higher Order Components.',
      "Has experience working with GraphQL Apollo client (more specifically familiarity with Connections). Bonus points if you've worked with subscriptions too."
    ],
    benefit: {
      name: ['Salary Range', 'Equity', 'Bonus'],
      value: ['$ 120 - 180K', '2.50 %', 'Cash or Equity']
    }
  },
  {
    position: 'Junior Software Developer',
    chips: ['Software', 'Stock Options'],
    description: [
      'Familiar with git, including making pull requests, opening up feature branches, and updating code from reviewers.',
      'Has a working understanding of Flexbox and can design solutions to most common layout issues.',
      'Has a good conceptual understanding of how to design / build for multiple screen sizes.'
    ],
    benefit: {
      name: ['Salary Range', 'Equity', 'Bonus'],
      value: ['$ 70 - 90K', '1.00 %', 'Cash or Equity']
    }
  },
  {
    position: 'Marketing and Sales',
    chips: ['Marketing', 'Sales', 'Stock Options'],
    description: [
      'We are not currently actively hiring for this position, but we are always looking for talented people to join our team. If you are interested in working with us, please send us an email at hello@emitiq.com'
    ]
  }
];

export const CAREER_BENEFITS = [
  'Large stock option pool.',
  'Standing desk, chair, monitor and everything else you need for a perfect office setup.',
  'Hardware of your choice.',
  'Flexible paid holidays.',
  'Investment into your continued growth as a leader.',
  '$100/month in health and fitness credits.',
  'Team events & meals.',
  'Pension plan set for 2023.'
];

export const ORGANIZATION_PAGE = {
  VEHICLES: 'vehicles',
  LOCATIONS: 'locations',
  EQUIPMENT: 'equipment'
} as const;

export const INVENTORY_PAGE = {
  MEASURE: 'measure',
  REDUCE: 'reduce',
  SHARE: 'share',
  CERTIRY: 'certify'
} as const;

export const BRANCH_TYPE = {
  IMAGE: 'IMAGE',
  CSV: 'CSV',
  SURVEY: 'SURVEY',
  SURVEY_RESPONSE: 'SURVEY_RESPONSE',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  UNKNOWN: 'UNKNOWN',
  NODE: 'node'
};
export type BRANCH_TYPES =
  | 'IMAGE'
  | 'CSV'
  | 'SURVEY'
  | 'SURVEY_RESPONSE'
  | 'node';

export const ICONS_BY_BRANCH_TYPE = {
  [BRANCH_TYPE.IMAGE]: 'uploads',
  [BRANCH_TYPE.CSV]: 'uploads',
  [BRANCH_TYPE.SURVEY]: 'surveys',
  [BRANCH_TYPE.SURVEY_RESPONSE]: 'account',
  [BRANCH_TYPE.NODE]: 'emissions'
} as const;

export const NODE_TYPES = {
  BRANCH: 'BRANCH',
  MEASUREMENT: 'MEASUREMENT'
} as const;

export type GoalFrequenciesType =
  | 'DAILY'
  | 'WEEKLY'
  | 'MONTHLY'
  | 'YEARLY';

export const GOAL_FREQUENCIES: {
  [key in GoalFrequenciesType]: string;
} = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
};

export const FREQUENCY_LABELS: {
  [key in GoalFrequenciesType]: string;
} = {
  DAILY: 'day',
  WEEKLY: 'week',
  MONTHLY: 'month',
  YEARLY: 'year'
};

interface BenefitsContentType {
  icon: JSX.Element;
  title: string;
  description: string;
}

interface TimelineContentType {
  title: string;
  description: string;
}

export const footprintBenefitsContent: BenefitsContentType[] = [
  {
    icon: (
      <FilePlus
        style={{
          stroke: '#151518',
          strokeWidth: 1.4,
          transform: 'scale(1.4) translateY(1px)',
          marginInline: '0.5rem'
        }}
      />
    ),
    title: 'Automated Calculations',
    description:
      'Analyze your bank transactions and calculate your footprint'
  },
  {
    icon: (
      <BadgeCheck
        style={{
          stroke: '#151518',
          strokeWidth: 1.4,
          transform: 'scale(1.7) translateY(1px)',
          marginInline: '0.5rem'
        }}
      />
    ),
    title: 'Share Your Story',
    description:
      'Show your customers and investors your commitment to the planet'
  },
  {
    icon: (
      <CalendarDays
        style={{
          stroke: '#151518',
          strokeWidth: 1.4,
          transform: 'scale(1.7) translateY(1px)',
          marginInline: '0.5rem'
        }}
      />
    ),
    title: 'Set Commitments & Track Progress',
    description: 'Reduce your footprint and save money'
  }
];

export const marketplaceBenefitsContent: BenefitsContentType[] = [
  {
    icon: <GeneralIcon type="search" mode="dark" size="lg" />,
    title: 'See Every Detail with Transparency',
    description: `You have the option to dive intothe project details. 
    View every step, measurement, and sign-off. 
    This gives you incomparable levels of oversight.`
  },
  {
    icon: <GeneralIcon type="marketplace" mode="dark" size="md" />,
    title: 'The Only Marketplace that can Ensure Integrity',
    description: `All offsets are from projects developed on our platform.
    Every tree planted and auditor checkmark is recorded.
    `
  },
  {
    icon: <GeneralIcon type="confirm" mode="dark" size="md" />,
    title: 'Verified Using Internationally Recognized Standards',
    description: `Verification at every single step of a project.
    Including meeting and surpassing globally recognized standards.
    `
  }
];

export const developmentBenefitsContent: BenefitsContentType[] = [
  {
    icon: <GeneralIcon type="search" mode="dark" size="md" />,
    title: 'Easier Development Process',
    description: ``
  },
  {
    icon: <GeneralIcon type="marketplace" mode="dark" size="md" />,
    title: 'More Valuable Credits',
    description: ``
  },
  {
    icon: <GeneralIcon type="confirm" mode="dark" size="md" />,
    title: 'Develop New Standards for your Unique Project',
    description: ``
  }
];

export const timelineContent: TimelineContentType[] = [
  {
    title: 'Connect Financial Accounts',
    description:
      'Historical transactions are pulled in automatically, and new ones when they occur.'
  },
  {
    title: 'Add Receipts to Refine Calculations',
    description:
      'Receipts and invoices can give line item level detail. Our platform parses any receipts provided, to get more exact emissions calculations.'
  },
  {
    title: 'Analyze Transactions',
    description:
      'The things you buy contribute to climate change. Our AI-powered system automatically categorizes transactions and calculates their related emissions.'
  },
  {
    title: 'Organize Your Business',
    description:
      'Monitor emissions by locations, departments or whatever fits with your business. Our platform supports full coverage of scopes 1,2, and 3.'
  }
];

interface FourSectionContentProps {
  title: string;
  description: string;
  image: JSX.Element;
}

export interface ReportingCardProps {
  title: string;
  description: string;
  index: number;
  subheader1?: string;
  subheader1Content?: string;
  subheader2?: string;
  subheader2Content?: string;
  readMoreLink: string;
  steps?: {
    icon: GeneralIconType;
    tooltip: string;
    text: string;
  }[];
}

export const fourSectionContent: FourSectionContentProps[] = [
  {
    title: 'Credibility',
    description:
      'With third-party validation, transparency throughout the process, and adherence to internationally recognized standards, you can be confident in your carbon neutral status.',
    image: <GeneralIcon type="confirm" mode="dark" size="lg" />
  },
  {
    title: 'Proven Methodology',
    description:
      'EmitIQ Neutral™ is a unique certification based on globally recognized standards that demonstrates your commitment to combatting the climate crisis, based on ISO 14064-1 and PAS 2060.',
    image: <GeneralIcon type="microscope" mode="dark" size="lg" />
  },
  {
    title: 'Fits Your Business',
    description:
      'Customizable to your business, EmitIQ Neutral™ is designed to be flexible and scalable, so that you can achieve carbon neutral status at your own pace.',
    image: (
      <GeneralIcon
        type="marketplace"
        mode="dark"
        size="lg"
        style={{ transform: 'scale(1.5)' }}
      />
    )
  },
  {
    title: 'Shareable',
    description:
      'Display your badge of climate action and demonstrate environmental responsibility, attract environmentally conscious customers, and enhance brand reputation, while also inspiring others to take similar climate-friendly actions',
    image: <GeneralIcon type="share" mode="dark" size="lg" />
  }
];

export const reportingEvents = [
  {
    id: 2,
    start: Date.parse('2023-07-25'),
    end: Date.parse('2023-08-15'),
    label: 'Generate carbon footprint baseline',
    color: 'secondary.dark'
  },
  {
    id: 3,
    start: Date.parse('2023-08-15'),
    end: Date.parse('2023-8-16'),
    label: 'Create Carbon Reduction Plan',
    color: 'primary.dark'
  },
  {
    id: 4,
    start: Date.parse('2023-08-16'),
    end: Date.parse('2023-8-31'),
    label: 'Reduce emissions',
    color: 'secondary.dark'
  },
  {
    id: 5,
    start: Date.parse('2023-08-31'),
    end: Date.parse('2023-09-01'),
    label: 'Offset remaining emissions',
    color: 'primary.dark'
  },
  {
    id: 6,
    start: Date.parse('2023-09-01'),
    end: Date.parse('2023-9-12'),
    label: 'GHG Audit & Reporting',
    color: 'secondary.main'
  },
  {
    id: 7,
    start: Date.parse('2023-09-12'),
    end: Date.parse('2023-09-13'),
    label: 'EmitIQ Neutral™ Certification',
    color: 'primary.main'
  }
];

export const reportingCards: ReportingCardProps[] = [
  {
    title: 'ISO 14064-1 for Carbon Verification',
    index: 0,
    description: `ISO 14064-1 is the international standard for carbon
    verification. It provides a framework for the development,
    implementation, maintenance, and improvement of an
    organization’s GHG inventory. EmitIQ offers a
    verification service to help organizations achieve ISO 14064-1
    status.`,
    subheader1: `EmitIQ Carbon Reduction Plan`,
    subheader1Content: `Our Carbon Accounting platform, powered by AI, lets you quickly determine your carbon inventory 
    for your organization's required boundaries (direct or indirect and scopes 1, 2, and 3). 
    Our team of GHG pros will help create targets and an actionable plan to achieve emissions reduction goals.`,
    subheader2: `Audit Support for ISO 14064-1`,
    subheader2Content: `EmitIQ delivers full audit support including the generation of an ISO 14064-1 compliant report consistent with the requirements of the standard, along with verified emissions data and a verification statement.`,
    readMoreLink: 'iso14064'
  },
  {
    title: 'PAS 2060 for Carbon Neutrality',
    index: 1,
    description: `PAS 2060 is the international standard for carbon neutrality.
    It provides a framework for the development, implementation,
    maintenance, and improvement of an organization’s carbon
    neutrality. EmitIQ offers a certification service to help
    organizations achieve PAS 2060 status.`,
    subheader1: `EmitIQ Carbon Reduction Plan`,
    subheader1Content: `Once emissions are quantified, we will work with your team to develop and implement a robust carbon management plan. This plan will outline specific targets 
    and strategies for reducing emissions, improving energy efficiency, and transitioning to renewable energy sources. It will demonstrate active management of emissions to achieve 
    reductions. EmitIQ Neutral™ PAS 2060 requires organizations to offset any remaining emissions that cannot be eliminated through reduction efforts. We will help you invest 
    in high-quality offset projects that meet certain eligibility criteria through the Offset Marketplace.`,
    subheader2: `Audit Support for PAS 2060`,
    subheader2Content: `EmitIQ provides catered support to ensure you meet the requirements of the PAS 2060 standard, including full auditing services and support. EmitIQ 
    Neutral™ PAS 2060 certification is not a one-time achievement. Organizations must maintain their carbon neutrality efforts and undergo regular monitoring and verification to ensure 
    continued compliance. This may involve periodic audits and reporting to demonstrate ongoing emission reductions and offsetting activities.`,
    steps: [
      {
        icon: 'inventory',
        tooltip:
          'Quantify emissions with EmitIQ Carbon Accounting platform',
        text: 'Quantify'
      },
      {
        icon: 'goals',
        tooltip:
          'Site(s) visit to verify certain aspects of the GHG inventory',
        text: 'Verify'
      },
      {
        icon: 'surveys',
        tooltip:
          'Create a Carbon Reduction Plan using our goal setting tools',
        text: 'Plan'
      },
      {
        icon: 'recycling',
        tooltip:
          'Reduce emissions, improve energy efficiency, and use renewables',
        text: 'Reduce'
      },
      {
        icon: 'marketplace',
        tooltip:
          'Offset remaining emissions through the Offsets Marketplace',
        text: 'Offset'
      },
      {
        icon: 'goals',
        tooltip: 'Achieve PAS 2060 status and share digital badge',
        text: 'Certify'
      }
    ],
    readMoreLink: 'pas2060'
  }
];

export const MEASUREMENT_STATE_COLORS = {
  [MeasurementStatesEnum.Recorded]: 'warning',
  [MeasurementStatesEnum.ToReview]: 'warning',
  [MeasurementStatesEnum.Public]: 'primary',
  [MeasurementStatesEnum.Archived]: 'secondary',
  [MeasurementStatesEnum.Excluded]: 'info',
  [MeasurementStatesEnum.Failed]: 'error'
};

export const SKELETON_COLOR = 'info.dark';

export const TIMELINE_ZOOM_LEVEL = {
  month: 'month',
  year: 'year',
  decade: 'decade'
} as const;
export type TimelineZoomLevelType = keyof typeof TIMELINE_ZOOM_LEVEL;
export const TIMELINE_HEADER_ROW_HEIGHT = 58;

export const DAY_IN_MILLISECONDS = 1000 * 60 * 60 * 24;

export const SCOPE_COLORS = {
  1: '#7ed9a2',
  2: '#ffbe5d',
  3: '#cc9aff'
};

export const PILL_HEIGHT = 40;

// Important to keep this object in order of LOWEST plan tier to HIGHEST plan tier
// Components use indices to compare plan tiers
export const SUBSCRIPTION_PLANS: {
  [key in SubscriptionPlanTiersEnum]: SubscriptionPlanType;
} = {
  [SubscriptionPlanTiersEnum.Pro]: {
    tier: SubscriptionPlanTiersEnum.Pro,
    name: 'EmitIQ Pro',
    pricePerMonth: null,
    pricePerYear: null,
    topFeatures: [
      'Up to 100 AI Measurements',
      'Public Share Page',
      'EmitHub Company Directory',
      'Carbon Reduction Tools'
    ]
  },
  [SubscriptionPlanTiersEnum.ProPlus]: {
    tier: SubscriptionPlanTiersEnum.ProPlus,
    name: 'EmitIQ Pro+',
    pricePerMonth: 99,
    pricePerYear: 90,
    topFeatures: [
      'Custom Public Share Page',
      'Unlimited AI Measurements',
      'ERP Integration',
      'AI Reduction Strategies'
    ]
  },
  [SubscriptionPlanTiersEnum.Advanced]: {
    tier: SubscriptionPlanTiersEnum.Advanced,
    name: 'EmitIQ Advanced',
    pricePerMonth: 249,
    pricePerYear: 225,
    topFeatures: [
      '5 User Licenses',
      'EmitView Advanced Dashboard',
      'Custom Emission Factors',
      'AI-Generated Audit Reports'
    ]
  },
  [SubscriptionPlanTiersEnum.Enterprise]: {
    tier: SubscriptionPlanTiersEnum.Enterprise,
    name: 'EmitIQ Enterprise',
    pricePerMonth: null,
    pricePerYear: null,
    topFeatures: [
      'Unlimited User Licenses',
      'Custom Emission Factors',
      'AI-Generated Audit Reports',
      'Custom AI Models'
    ]
  }
};

// For advanced plan only (prices per month)
export const PROMOTION_PRICES = {
  VICFOUNDERS24: {
    [SubscriptionFrequenciesEnum.Month]: '99.60',
    [SubscriptionFrequenciesEnum.Year]: '83.30'
  }
};

export function getTailwindTheme() {
  return resolveConfig(tailwindConfig).theme;
}

export type BadgeType = {
  name: string;
  minPoints: number;
  maxPoints: number;
  percentile: number;
};

export const BADGES: { [key in BadgesEnum]: BadgeType } = {
  BRONZE: {
    name: 'Bronze',
    minPoints: 0,
    maxPoints: 5,
    percentile: 30
  },
  SILVER: {
    name: 'Silver',
    minPoints: 6,
    maxPoints: 10,
    percentile: 10
  },
  GOLD: {
    name: 'Gold',
    minPoints: 11,
    maxPoints: 15,
    percentile: 5
  },
  DIAMOND: {
    name: 'Diamond',
    minPoints: 16,
    maxPoints: 20,
    percentile: 1
  }
};

export const DEFAULT_ROW_CLASSNAME =
  'h-row border border-l-transparent relative transition-all mb-[-1px] flex cursor-pointer bg-background flex-nowrap w-full min-w-full items-center overflow-clip row';
export const ACTIVE_ROW_CLASSNAME =
  DEFAULT_ROW_CLASSNAME + ' bg-primary/10';
export const DROPZONE_COLOR_CLASSNAME = ' ring-2 ring-inset';
export const DROPZONE_CANDROP_COLOR_CLASSNAME = ' ring-[#3b82f633]';
export const DROPZONE_ROW_CLASSNAME =
  DEFAULT_ROW_CLASSNAME + DROPZONE_COLOR_CLASSNAME;

export function updateRefs(
  refs: MutableRefObject<object>,
  key: string,
  value: unknown,
  nodeId: string
) {
  if (nodeId && refs) {
    if (!refs.current[nodeId]) {
      refs.current[nodeId] = {};
    }
    if (refs.current[nodeId]) {
      refs.current[nodeId][key] = value;
    }
  }
}

export const updateRowClassName = ({
  rowRefs,
  nodeId,
  className
}: {
  rowRefs: MutableRefObject<object>;
  nodeId: string;
  className: string;
}) => {
  if (rowRefs.current[nodeId]) {
    // Locked
    if (rowRefs.current[nodeId].locked) {
      rowRefs.current[nodeId].locked.className = className;
    }

    // Unlocked
    if (rowRefs.current[nodeId].unlocked) {
      rowRefs.current[nodeId].unlocked.className = className;
    }
  }
};

export const ACTION_STATE_COLOURS = {
  [GoalActionStatesEnum.Backlog]: '##72a0cc',
  [GoalActionStatesEnum.InProgress]: '#aaaaaa',
  [GoalActionStatesEnum.Done]: '#69a96b',
  [GoalActionStatesEnum.Cancelled]: '#c54646',
  [GoalActionStatesEnum.InReview]: '#cfba5b',
  [GoalActionStatesEnum.Todo]: '#ca7d3e'
};

export const COUNTRY_CODES = {
  Afghanistan: 'AF',
  'Åland Islands': 'AX',
  Albania: 'AL',
  Algeria: 'DZ',
  'American Samoa': 'AS',
  Andorra: 'AD',
  Angola: 'AO',
  Anguilla: 'AI',
  Antarctica: 'AQ',
  'Antigua and Barbuda': 'AG',
  Argentina: 'AR',
  Armenia: 'AM',
  Aruba: 'AW',
  Australia: 'AU',
  Austria: 'AT',
  Azerbaijan: 'AZ',
  Bahamas: 'BS',
  Bahrain: 'BH',
  Bangladesh: 'BD',
  Barbados: 'BB',
  Belarus: 'BY',
  Belgium: 'BE',
  Belize: 'BZ',
  Benin: 'BJ',
  Bermuda: 'BM',
  Bhutan: 'BT',
  Bolivia: 'BO',
  'Bonaire Sint Eustatius and Saba': 'BQ',
  'Bosnia and Herzegovina': 'BA',
  Botswana: 'BW',
  'Bouvet Island': 'BV',
  Brazil: 'BR',
  'British Indian Ocean Territory': 'IO',
  'Brunei Darussalam': 'BN',
  Bulgaria: 'BG',
  'Burkina Faso': 'BF',
  Burundi: 'BI',
  'Cabo Verde': 'CV',
  Cambodia: 'KH',
  Cameroon: 'CM',
  Canada: 'CA',
  'Cayman Islands': 'KY',
  'Central African Republic': 'CF',
  Chad: 'TD',
  Chile: 'CL',
  China: 'CN',
  'Christmas Island': 'CX',
  'Cocos Keeling Islands': 'CC',
  Colombia: 'CO',
  Comoros: 'KM',
  'Republic of the Congo': 'CG',
  'Democratic Republic of the Congo': 'CD',
  'Cook Islands': 'CK',
  'Costa Rica': 'CR',
  "Côte d'Ivoire": 'CI',
  Croatia: 'HR',
  Cuba: 'CU',
  Curaçao: 'CW',
  Cyprus: 'CY',
  Czechia: 'CZ',
  Denmark: 'DK',
  Djibouti: 'DJ',
  Dominica: 'DM',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  'El Salvador': 'SV',
  'Equatorial Guinea': 'GQ',
  Eritrea: 'ER',
  Estonia: 'EE',
  Ethiopia: 'ET',
  'Falkland Islands': 'FK',
  'Faroe Islands': 'FO',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  'French Guiana': 'GF',
  'French Polynesia': 'PF',
  'French Southern Territories': 'TF',
  Gabon: 'GA',
  Gambia: 'GM',
  Georgia: 'GE',
  Germany: 'DE',
  Ghana: 'GH',
  Gibraltar: 'GI',
  Greece: 'GR',
  Greenland: 'GL',
  Grenada: 'GD',
  Guadeloupe: 'GP',
  Guam: 'GU',
  Guatemala: 'GT',
  Guernsey: 'GG',
  Guinea: 'GN',
  'Guinea-Bissau': 'GW',
  Guyana: 'GY',
  Haiti: 'HT',
  'Heard Island and McDonald Islands': 'HM',
  'Holy See': 'VA',
  Honduras: 'HN',
  'Hong Kong': 'HK',
  Hungary: 'HU',
  Iceland: 'IS',
  India: 'IN',
  Indonesia: 'ID',
  Iran: 'IR',
  Iraq: 'IQ',
  Ireland: 'IE',
  'Isle of Man': 'IM',
  Israel: 'IL',
  Italy: 'IT',
  Jamaica: 'JM',
  Japan: 'JP',
  Jersey: 'JE',
  Jordan: 'JO',
  Kazakhstan: 'KZ',
  Kenya: 'KE',
  Kiribati: 'KI',
  'North Korea': 'KP',
  'South Korea': 'KR',
  Kuwait: 'KW',
  Kyrgyzstan: 'KG',
  Laos: 'LA',
  Latvia: 'LV',
  Lebanon: 'LB',
  Lesotho: 'LS',
  Liberia: 'LR',
  Libya: 'LY',
  Liechtenstein: 'LI',
  Lithuania: 'LT',
  Luxembourg: 'LU',
  Macao: 'MO',
  Macedonia: 'MK',
  Madagascar: 'MG',
  Malawi: 'MW',
  Malaysia: 'MY',
  Maldives: 'MV',
  Mali: 'ML',
  Malta: 'MT',
  'Marshall Islands': 'MH',
  Martinique: 'MQ',
  Mauritania: 'MR',
  Mauritius: 'MU',
  Mayotte: 'YT',
  Mexico: 'MX',
  Micronesia: 'FM',
  Moldova: 'MD',
  Monaco: 'MC',
  Mongolia: 'MN',
  Montenegro: 'ME',
  Montserrat: 'MS',
  Morocco: 'MA',
  Mozambique: 'MZ',
  Myanmar: 'MM',
  Namibia: 'NA',
  Nauru: 'NR',
  Nepal: 'NP',
  Netherlands: 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nicaragua: 'NI',
  Niger: 'NE',
  Nigeria: 'NG',
  Niue: 'NU',
  'Norfolk Island': 'NF',
  'Northern Mariana Islands': 'MP',
  Norway: 'NO',
  Oman: 'OM',
  Pakistan: 'PK',
  Palau: 'PW',
  'Palestine, State of': 'PS',
  Panama: 'PA',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Pitcairn: 'PN',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Qatar: 'QA',
  Réunion: 'RE',
  Romania: 'RO',
  'Russian Federation': 'RU',
  Rwanda: 'RW',
  'Saint Barthélemy': 'BL',
  'Saint Helena Ascension and Tristan da Cunha': 'SH',
  'Saint Kitts and Nevis': 'KN',
  'Saint Lucia': 'LC',
  'Saint Martin': 'MF',
  'Saint Pierre and Miquelon': 'PM',
  'Saint Vincent and the Grenadines': 'VC',
  Samoa: 'WS',
  'San Marino': 'SM',
  'Sao Tome and Principe': 'ST',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Seychelles: 'SC',
  'Sierra Leone': 'SL',
  Singapore: 'SG',
  'Sint Maarten': 'SX',
  Slovakia: 'SK',
  Slovenia: 'SI',
  'Solomon Islands': 'SB',
  Somalia: 'SO',
  'South Africa': 'ZA',
  'South Georgia and the South Sandwich Islands': 'GS',
  'South Sudan': 'SS',
  Spain: 'ES',
  'Sri Lanka': 'LK',
  Sudan: 'SD',
  Suriname: 'SR',
  'Svalbard and Jan Mayen': 'SJ',
  Swaziland: 'SZ',
  Sweden: 'SE',
  Switzerland: 'CH',
  'Syrian Arab Republic': 'SY',
  Taiwan: 'TW',
  Tajikistan: 'TJ',
  Tanzania: 'TZ',
  Thailand: 'TH',
  'Timor-Leste': 'TL',
  Togo: 'TG',
  Tokelau: 'TK',
  Tonga: 'TO',
  'Trinidad and Tobago': 'TT',
  Tunisia: 'TN',
  Turkey: 'TR',
  Turkmenistan: 'TM',
  'Turks and Caicos Islands': 'TC',
  Tuvalu: 'TV',
  Uganda: 'UG',
  Ukraine: 'UA',
  'United Arab Emirates': 'AE',
  'United Kingdom of Great Britain and Northern Ireland': 'GB',
  'United States of America': 'US',
  'United States Minor Outlying Islands': 'UM',
  Uruguay: 'UY',
  Uzbekistan: 'UZ',
  Vanuatu: 'VU',
  Venezuela: 'VE',
  'Viet Nam': 'VN',
  'Virgin Islands British': 'VG',
  'Virgin Islands US': 'VI',
  'Wallis and Futuna': 'WF',
  'Western Sahara': 'EH',
  Yemen: 'YE',
  Zambia: 'ZM',
  Zimbabwe: 'ZW'
};

const iconClassName = 'w-icon h-icon';
export const EMISSION_CATEGORIES = {
  energy: {
    ghgProtocols: [
      GhgProtocolsEnum.PurchasedCooling,
      GhgProtocolsEnum.PurchasedElectricity,
      GhgProtocolsEnum.PurchasedHeat,
      GhgProtocolsEnum.PurchasedSteam
    ],
    color: EMISSION_CATEGORY_COLOURS.energy,
    icon: <Lightbulb className={iconClassName} />
  },
  operations: {
    ghgProtocols: [
      GhgProtocolsEnum.CompanyVehicles,
      GhgProtocolsEnum.CompanyFacilities
    ],
    color: EMISSION_CATEGORY_COLOURS.operations,
    icon: <Warehouse className={iconClassName} />
  },
  upstream: {
    ghgProtocols: [
      GhgProtocolsEnum.PurchasedGoodsAndServices,
      GhgProtocolsEnum.CapitalGoods,
      GhgProtocolsEnum.FuelAndEnergyRelatedActivities,
      GhgProtocolsEnum.UpstreamTransportationAndDistribution,
      GhgProtocolsEnum.WasteGeneratedInOperations,
      GhgProtocolsEnum.BusinessTravel,
      GhgProtocolsEnum.EmployeeCommuting,
      GhgProtocolsEnum.UpstreamLeasedAssets
    ],
    color: EMISSION_CATEGORY_COLOURS.upstream,
    icon: <CloudUpload className={iconClassName} />
  },
  downstream: {
    ghgProtocols: [
      GhgProtocolsEnum.DownstreamTransportationAndDistribution,
      GhgProtocolsEnum.ProcessingOfSoldProducts,
      GhgProtocolsEnum.UseOfSoldProducts,
      GhgProtocolsEnum.EndOfLifeTreatmentOfSoldProducts,
      GhgProtocolsEnum.DownstreamLeasedAssets,
      GhgProtocolsEnum.Franchises,
      GhgProtocolsEnum.Investments
    ],
    color: EMISSION_CATEGORY_COLOURS.downstream,
    icon: <CloudDownload className={iconClassName} />
  },
  other: {
    ghgProtocols: [GhgProtocolsEnum.Unknown, GhgProtocolsEnum.Other],
    color: EMISSION_CATEGORY_COLOURS.other,
    icon: <AlignJustify className={iconClassName} />
  }
};

// Customizes the Stripe components to our branding
export const STRIPE_ELEMENTS_APPEARANCE: Appearance = {
  variables: {
    colorBackground: 'hsl(210, 100%, 98%)',
    focusBoxShadow: '0 0 0 0px',
    borderRadius: '0.5rem'
  },
  rules: {
    '.Input': {
      border: '1px solid hsl(240, 2%, 78%)',
      boxShadow: 'none',
      fontSize: '0.875rem',
      fontWeightNormal: '300',
      fontLineHeight: '1.25rem',
      letterSpacing: '0.025em'
    },
    '.Label': {
      marginBottom: '10px',
      fontSize: '0.875rem',
      fontWeightNormal: '300',
      fontLineHeight: '1.25rem',
      letterSpacing: '0.025em',
      colorText: 'hsl(0, 0%, 27%)'
    },
    '.Error': {
      fontSize: '0.8rem',
      fontWeightNormal: '300',
      fontLineHeight: '1.25rem',
      letterSpacing: '0.025em',
      marginTop: '10px'
    },
    '.Input::placeholder': {
      color: 'hsl(0, 0%, 53%)',
      fontSize: '0.875rem',
      fontWeightNormal: '300',
      fontLineHeight: '1.25rem',
      letterSpacing: '0.025em'
    },
    '.Input:focus': {
      borderColor: 'hsl(221, 83%, 53%)'
    },
    '.Input--invalid': {
      border: '1px solid hsl(14, 99%, 40%)'
    }
  }
};

export const DATA_CALCULATOR_TYPES_BY_SCOPE = {
  1: [
    DataCalculatorTypesEnum.LocationNaturalGas,
    DataCalculatorTypesEnum.VehicleFuel,
    DataCalculatorTypesEnum.VehicleDistance,
    DataCalculatorTypesEnum.EquipmentFuel,
    DataCalculatorTypesEnum.EquipmentRefrigerant,
    DataCalculatorTypesEnum.EquipmentIndustrialGas
  ],
  2: [
    DataCalculatorTypesEnum.LocationElectricity,
    DataCalculatorTypesEnum.LocationHeatOrSteam,
    DataCalculatorTypesEnum.LocationPurchasedCooling
  ]
};

export const DATA_CALCULATOR_TYPE_TO_GHG_CATEGORY = {
  [DataCalculatorTypesEnum.LocationNaturalGas]:
    GhgProtocolsEnum.CompanyFacilities,
  [DataCalculatorTypesEnum.VehicleFuel]:
    GhgProtocolsEnum.CompanyVehicles,
  [DataCalculatorTypesEnum.VehicleDistance]:
    GhgProtocolsEnum.CompanyVehicles,
  [DataCalculatorTypesEnum.EquipmentFuel]:
    GhgProtocolsEnum.CompanyFacilities,
  [DataCalculatorTypesEnum.EquipmentRefrigerant]:
    GhgProtocolsEnum.CompanyFacilities,
  [DataCalculatorTypesEnum.EquipmentIndustrialGas]:
    GhgProtocolsEnum.CompanyFacilities,
  [DataCalculatorTypesEnum.LocationElectricity]:
    GhgProtocolsEnum.PurchasedElectricity,
  [DataCalculatorTypesEnum.LocationHeatOrSteam]:
    GhgProtocolsEnum.PurchasedHeat,
  [DataCalculatorTypesEnum.LocationPurchasedCooling]:
    GhgProtocolsEnum.PurchasedCooling
};

export const GHG_CATEGORY_ICONS = {
  [GhgProtocolsEnum.CompanyFacilities]: (
    <Building className={iconClassName} />
  ),
  [GhgProtocolsEnum.CompanyVehicles]: (
    <Car className={iconClassName} />
  ),
  [GhgProtocolsEnum.PurchasedElectricity]: (
    <Zap className={iconClassName} />
  ),
  [GhgProtocolsEnum.PurchasedHeat]: (
    <Waves className={iconClassName} />
  ),
  [GhgProtocolsEnum.PurchasedCooling]: (
    <Fan className={iconClassName} />
  ),
  [GhgProtocolsEnum.PurchasedSteam]: (
    <Waves className={iconClassName} />
  )
};

export const getScopeFromDataCalculatorType = (
  calculatorType: DataCalculatorTypesEnum
): ScopeType => {
  return Number(
    Object.keys(DATA_CALCULATOR_TYPES_BY_SCOPE).find((scope) => {
      return DATA_CALCULATOR_TYPES_BY_SCOPE[scope].includes(
        calculatorType
      );
    })
  ) as ScopeType;
};
